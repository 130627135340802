<template>
  <div id="box">
    <Header> 绑定第三方 </Header>

    <div>
      <div class="way microblog">
        <div>微博</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div>
      <div class="way">
        <div>微信</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div>
      <div class="way Alipay" @click="Alipay">
        <div>支付宝</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //   返回上一页
    back() {
      this.$router.push('/set');
    },

    // 绑定支付宝
    Alipay() {
      this.$router.push('/Alipay');
    },
  },
};
</script>

<style lang="less" scoped>
#box {
  height: 100vh;
  background-color: #f3f4f8;
  font-size: 13px;
  text-align: center;
  .microblog {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .Alipay {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .way {
    display: flex;
    justify-content: space-between;
    width: 341px;
    background-color: #ffffff;
    height: 46px;
    align-items: center;
    border-bottom: 1px solid #f8f8f8;
    margin-left: 17px;
    img {
      width: 7px;
      height: 12px;
      margin-right: 10px;
    }
    div {
      margin-left: 17px;
    }
  }
}
.topHeader {
  margin-bottom: 21px;
}
</style>
